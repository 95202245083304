<!-- System: STA
    Purpose: In active projects user can see his all archive
          projects.
-->
<template>
  <!-- Projects Card  -->
  <v-card
    class="mx-auto my-auto dashboard-boxes"
    max-width="250"
    elevation="10"
  >
    <!-- Projects Image  -->
    <img
      :src="base_url + 'storage/' + project.logo"
      class="img-fluid proj-img mt-2"
      @error="
        $event.target.src = require('../../assets/images/dashboard_icons/no_image.svg')
      "
      height="150"
      width="200"
    />
    <!-- tooltip For Project Name  -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <h3
          class="text-center mt-2"
          v-bind="attrs"
          v-on="on"
          v-if="project.project_name.length <= 20"
        >
          {{ project.project_name }}
        </h3>
        <h3 class="text-center mt-2" v-bind="attrs" v-on="on" v-else>
          {{ project.project_name.slice(0, 18) + ".." }}
        </h3>
      </template>
      <span>{{ project.project_name }}</span>
    </v-tooltip>
    <!-- Project Actions -->
    <v-card-actions
      class="d-flex justify-center py-3"
      v-if="selectedCompanyUserStatus !== 'employee' && showPermission"
    >
      <v-btn color="primary" text @click="restoreProject(project)">
        <v-icon>mdi-restore</v-icon>
        Restore
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";

export default {
  name: "ArchiveProject",
  props: ["project"],
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    base_url() {
      return baseURL.API_URL;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.project) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    /**
     * This function emits a Custom event
     * for restore project dialog
     */
    restoreProject(project) {
      this.$root.$emit("restore-project", project);
    },
  },
};
</script>

<style scoped>
.dashboard-boxes {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, 0.1);
}

@media (min-width: 480px) and (max-width: 768px) {
  .img-fluid {
    width: 150px;
  }
}
</style>
